import styled from 'styled-components'
import React, { useEffect, useState, useRef} from 'react';
import colors from "../../utils/style/color"
import { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'


const FlexContainerBubbleDIV = styled.div`
    display: flex;
    width: 100vw;
    height: ${(props) => props.$IsSpecialSocialBrowser ? "300px" : "50vh"};
    position: relative;
    background-color: ${ (props) => props.$backgroundDiv};
`

const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: ${(props) => props.$WebOrRob ? "url('/data/images/fixedBackEffect/web.webp') center center no-repeat" : "url('/data/images/fixedBackEffect/robotique.webp') center center no-repeat"};;
    background-size: cover;
    z-index: 0; /* Ensure it's behind the content */
`

const Bubblediv = styled.div`
  position: absolute;
  background-color:  ${colors.TextBackgroundTranspa5};
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem; /* Adjust font size as needed */
  color: #fff; /* Adjust text color as needed */
  text-align: center;
  cursor: pointer;
  will-change: transform;
  transition: box-shadow 1s;

  @media (min-width: 1800px){
        font-size: 1.1rem;
    }
    @media (min-width: 2800px){
        font-size: 3.1rem;
    }


  box-shadow: ${ (props) => (props.$bubbleEffect && props.$WebOrRob) ? "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(230, 130, 42),inset 0 0 16px rgb(230, 130, 42),0 0 32px rgb(230, 130, 42),inset 0 0 32px rgb(230, 130, 42); " : ""};
  box-shadow: ${ (props) => (props.$bubbleEffect && !props.$WebOrRob) ? "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(144, 200, 253),inset 0 0 16px rgb(144, 200, 253),0 0 32px rgb(144, 200, 253),inset 0 0 32px rgb(144, 200, 253); " : ""};


  &:hover{


    box-shadow: ${ (props) => (props.$WebOrRob) ? "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(230, 130, 42),inset 0 0 16px rgb(230, 130, 42),0 0 32px rgb(230, 130, 42),inset 0 0 32px rgb(230, 130, 42);" : ""};
    box-shadow: ${ (props) => (!props.$WebOrRob) ? "0 0 8px #fff,inset 0 0 8px #fff,0 0 16px rgb(144, 200, 253),inset 0 0 16px rgb(144, 200, 253),0 0 32px rgb(144, 200, 253),inset 0 0 32px rgb(144, 200, 253);" : ""};
    transition: box-shadow 0.2s;

  }
`

const BouncingBubbles = ({sizetmp, backgroundDiv, BubblePart, expContent, WebOrRob}) => {

  const { IsSpecialSocialBrowser } = useContext(ThemeContext)

    const [bubbles, setBubbles] = useState([]);
    const [pxContainerWidth, setpxContainerWidth] = useState(window.innerWidth); // Initialize with the initial width
    const [pxContainerHeight, setpxContainerHeight] = useState(()=>{ if(IsSpecialSocialBrowser) {return 300} else {return window.innerHeight * 0.5}}); // Initialize with the initial height
    const [enabled, setEnabled] = useState(true)

    const BubbleRef = useRef(null);

    const [initArrayEffectBubbleState, setInitArrayEffectBubbleState] = useState([])

    useEffect(() => {

      // Function to handle window resize
      const handleResize = () => {
        if(window.innerWidth > 810){
        setpxContainerWidth(window.innerWidth);
        setpxContainerHeight(()=>{ if(IsSpecialSocialBrowser) {return 300} else {return window.innerHeight * 0.5}});
        setEnabled(false)

      }
      };

      // Add event listener for window resize
      window.addEventListener('resize', handleResize);

      const createBubbleAnimationAll = () =>{
  
        // Générer une bulle avec des propriétés aléatoires
        const createBubble = (id, existingBubbles) => {
          
          // two components use that
          let idKey;
          if (WebOrRob){
            idKey = "w"
          }
          else{
            idKey = "r"
          }

          const size = sizetmp;
          let x,y;
          let isColliding;
          let securityWhileLoupExit = 0;
          let text = expContent[id]

          // Générer des positions x et y jusqu'à ce qu'une position valide soit trouvée
          do {
            securityWhileLoupExit ++;
            x = Math.random() * (pxContainerWidth - size)
            y = Math.random() * (pxContainerHeight - size)
            isColliding = false;

            // Vérifier s'il y a collision avec d'autres bulles
            for (const existingBubble of existingBubbles) {
              if (collidesWith(existingBubble, x, y, size)) {
                isColliding = true;
                break;
              }
            }
            
          }
          
            while (!((isColliding === false) || (securityWhileLoupExit>20)));

          let idBubble;
          if(securityWhileLoupExit>20){
            idBubble=-1}
          else{
            idBubble = id + idKey;
          }

          const directionX = Math.random() > 0.5 ? 1 : -1;
          const directionY = Math.random() > 0.5 ? 1 : -1;

          return { idBubble, size, x, y, directionX, directionY, text };
        };

      // Fonction de détection de collision
      const collidesWith = (bubble, x, y, size) => {
        const dx = x - bubble.x;
        const dy = y - bubble.y;
        const distance = Math.sqrt(dx * dx + dy * dy);
        return distance < (size / 2 + bubble.size / 2);
      };
  
    // Générer des bulles initiales
    let initialBubbles = [];
    for (let i = 0; i < expContent.length; i++) {
      const newBubble = createBubble(i, initialBubbles);
      if(newBubble.idBubble !== -1){
        initialBubbles.push(newBubble);
      }
    }

    setBubbles(initialBubbles);
    setInitArrayEffectBubbleState(new Array(initialBubbles.length).fill(false))

    // Gérer le mouvement des bulles
    const moveBubbles = (prevBubbles) => {
    let newBubbles = prevBubbles.slice(); // Make a copy of the previous bubbles array

    const containerWidth = window.innerWidth;

    let containerHeight = window.innerHeight * 0.5;
    if(IsSpecialSocialBrowser) {
    containerHeight = 300;
    }

  
    newBubbles = newBubbles.map((bubble) => {
      const { size, x, y, directionX, directionY } = bubble;
      const speed = 0.5; 
  
      let newX = x + directionX * speed;
      let newY = y + directionY * speed;

      // Gérer les rebonds avec le bord
      if (newX + size > containerWidth || newX < 0) {
        
        bubble.directionX *= -1;
        newX = x + bubble.directionX * speed;
      }
  
      if (newY + size > containerHeight || newY < 0) {
        bubble.directionY *= -1;
        newY = y + bubble.directionY * speed;
      }

      // Gérer les collisions entre les bulles
      newBubbles.forEach((otherBubble) => {
        if (bubble !== otherBubble) {
          const dx = newX - otherBubble.x;
          const dy = newY - otherBubble.y;
          const distance = Math.sqrt(dx * dx + dy * dy);
  
          if (distance < (size / 2 + otherBubble.size / 2)) {
            // Les bulles se chevauchent, inversez leurs directions
            bubble.directionX *= -1;
            bubble.directionY *= -1;
            newX = x + bubble.directionX * speed;
            newY = y + bubble.directionY * speed;
          }
        }
      });
  
      return { ...bubble, x: newX, y: newY };
    });
  
    return newBubbles;
  };
  
  // Gérer le mouvement des bulles en utilisant requestAnimationFrame
  const animateBubbles = () => {
    
    setBubbles((prevBubbles) => moveBubbles(prevBubbles));
    if(enabled){
      requestAnimationFrame(animateBubbles)
      cancelAnimationFrame(animateBubbles)
      
    }
  };

  animateBubbles();

  return () =>{
    cancelAnimationFrame(animateBubbles);
    window.removeEventListener('resize', handleResize);
  };
   

  

  }

  createBubbleAnimationAll()


    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [enabled, pxContainerHeight, pxContainerWidth]);


    useEffect(() => {


      const effectUpdate = (prevEffect) => {
      let newEffect = prevEffect.slice(); // Make a copy of the previous array

      let isTrueEnable = false

      for (let i = 0; i < newEffect.length; i++) {

        if(newEffect[i] === true && i < newEffect.length-1 && !isTrueEnable){
          newEffect[i+1] = true;
          newEffect[i] = false;
          isTrueEnable = true;
          break;
        }
        else if(newEffect[i] === true && i === newEffect.length-1 && !isTrueEnable){
          newEffect[0] = true;
          newEffect[i] = false;
          isTrueEnable = true;
          break;
          
        }
        else if (newEffect[i] === false && i === newEffect.length-1 && !isTrueEnable){
          newEffect[0] = true;
          isTrueEnable = true;
          break;
        }

      }

      return newEffect;

      }

      const timer = setTimeout(() => {

        setInitArrayEffectBubbleState((prevEffect) => effectUpdate(prevEffect));

      }, 2000);
      return () => clearTimeout(timer);
    }, [initArrayEffectBubbleState]);

    return (

        <FlexContainerBubbleDIV $IsSpecialSocialBrowser={IsSpecialSocialBrowser} ref={BubbleRef} $backgroundDiv={backgroundDiv}>

          <BackgroundImage $WebOrRob={WebOrRob}/>

            {bubbles.map((bubble, i) => (
                    <Bubblediv $WebOrRob={WebOrRob} $bubbleEffect={initArrayEffectBubbleState[i]} $BubblePart={BubblePart} key={bubble.idBubble} style={{ width: `${bubble.size}px`, height: `${bubble.size}px`, left: `${bubble.x}px`, top: `${bubble.y}px`}}>{bubble.text}</Bubblediv>
                ))}

        </FlexContainerBubbleDIV>

    )
  }
  
  export default BouncingBubbles;