import styled from 'styled-components'
import colors from "../../utils/style/color"
import Blockmodel from '../portfolio/Blockmodel';
import React, {useState, useEffect} from 'react'
import { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'

// flex container global
const GlobalContainer = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    background-color: ${colors.TextBackground};
    color:white;
    padding-bottom: 3rem;
`

const TextTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100vw;
`

const H1 = styled.h1`
    position: relative;
    font-size: 2.5rem;
    letter-spacing: 0.4rem;
    font-weight: bold;
    text-align: center;
    padding-top: 3.5rem;

    text-shadow: 0 0 7px #fff,0 0 10px #72bf6a,0 0 13px #72bf6a,0 0 16px #72bf6a,0 0 20px #72bf6a;

    @media (max-width: 800px){
        font-size: 2rem;
        letter-spacing: 0.3rem;
    }

    @media (min-width: 1800px){
        font-size: 4rem;
    }
    @media (min-width: 2800px){
        font-size: 8rem;
    }
`

const H2 = styled.h2`
    font-size: 1.25rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1.5rem;

    @media (max-width: 800px){
        margin-left: 0.8rem;
        margin-right: 0.8rem;
    }

    @media (min-width: 1800px){
        font-size: 2.5rem;
    }
    @media (min-width: 2800px){
        font-size: 5rem;
    }
`

const PortfolioContainer = styled.div`
    display: flex;
    position: relative;
    align-items: start;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    width: 97%;
    margin: 1rem 2vw;
    padding-top: 1rem;
    padding-bottom: 1rem;

`

const Span = styled.span`
    display: block;
    width: 30vw;
    height: 3px;
    border: solid 1px;
    border-radius: 2px;
    margin: ${(props) => props.$portraitOrLandscape ? "0.5rem" : "1rem"};
    background-color: ${colors.BackgroundTitleRobPart};
    box-shadow:  0 0 8px #fff,inset 0 0 8px #fff,0 0 16px #72bf6a,inset 0 0 16px #72bf6a,0 0 32px #72bf6a,inset 0 0 32px #72bf6a;
`

const DivCutSection = styled.div`
    position:relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.35);
    height: ${(props) => props.$IsSpecialSocialBrowser ? "300px" : "35vh"};
    width: 100vw;
`

const Pcut = styled.p`
    color: white;
    font-size: 2.5rem;
    letter-spacing: 0.05rem;
    text-align: center;

    @media (max-width: 900px){
        font-size: 1.5rem;
    }
    @media (min-width: 2800px){
        font-size: 7rem;
    }
`

const WorkComponent = React.forwardRef((props,ref) => {

    const [portraitOrLandscape, setPortraitOrLandscapee] = useState(window.innerWidth < window.innerHeight);
    const { IsSpecialSocialBrowser } = useContext(ThemeContext)

    useEffect(() => {

        window.addEventListener( 'resize', onWindowResize );

        function onWindowResize() {

            if(window.innerWidth < window.innerHeight){
                setPortraitOrLandscapee(true)
            }
        
            else{setPortraitOrLandscapee(false)}
          }
          
        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('resize', onWindowResize);
    }

    }, [portraitOrLandscape]);


    const details = ["Élaboration de mon site professionnel en conformité avec les tendances actuelles et agrémenté d'une interaction 3D interactive sur la page d'accueil.",
                    "Réalisation d'un site vitrine dans l'univers du tatouage avec galeries photos, un formulaire de contact personnalisé et d'une fonction de commande automatique de flash pour des tatouages uniques et sur mesure.",
                    "Création d'un site e-commerce complet : une boutique en ligne spécialisée dans la vente de robots et de services robotiques, avec un tableau de bord administrateur entièrement personnalisé.",
                    ""
                    ]
    
    const skills = [
                    ["Conception d'animation 3D (blender) et intégration web (Threejs)", "Conception graphique et web design (GIMP)", "Intégration HTML/CSS/Javascript et animation des pages sous React", "Gestion formulaire de contact Backend sous Nodejs et Express", "Référencement naturel SEO", "Site responsive (compatibilité tout supports)", "Mises à jours selon les besoins"],
                    ["Conception graphique et intégration de plusieurs galeries photos", "Intégration HTML/CSS/Javascript et animation des pages sous React", "Gestion formulaire de contact Backend sous Nodejs et Express", "Référencement naturel SEO", "Site responsive (compatibilité tout supports)", "Maintenance et mises à jours selon les besoins client"],
                    ["Création d'une interface d'administration avec Next.js, MongoDB, AWS et NextAuth.js", "Création d'une plateforme de vente en ligne de robots avec gestion de produits et catégories", "Intégration de Stripe pour les paiements", "Gestion de base de données avec MongoDB et AWS", "Optimisation SEO et des performances globales avec rendu côté serveur et génération statique", "Création des APIs de gestion des données et des server actions", "Gestion des e-mails pour le suivi des livraisons et des transactions après paiement", "Déploiement de l'application sur Vercel et tests d'intégration"],
                    []
                   ]
    const skillsDetails = [
                    ["HTML", "CSS", "Javascript", "React", "NodeJs/Express", "Threejs", "Blender", "GIMP"],
                    ["HTML", "CSS", "Javascript", "React", "NodeJs/Express"],
                    ["Next.js", "React.js", "NextAuth.js","MongoDb","AWS", "Vercel", "Stripe","HTML", "CSS", "Javascript", "Resend-nextjs"],
                    []
                    ]

    const link = [
        ["https://sebastien-petit-dev.com/"],
        ["https://inkbynoux-tatoo.com/"],
        ["https://www.robrob-robots.com/"],
        ['']
        ]

    return (<div ref={ref}>
            <GlobalContainer> 

            <TextTitleContainer>
                <H1>PORTFOLIO</H1>
                <H2>Une partie de mon travail.</H2>
            </TextTitleContainer>

            <Span  $portraitOrLandscape={portraitOrLandscape}></Span>

            <PortfolioContainer>
                <Blockmodel portraitOrLandscape={portraitOrLandscape} imgPath={"/data/images/portfolio/sebadev.webp"} activityName={'SebaDev - Site vitrine prestations Web & Robotique'} type={'DEVELOPPEMENT WEB'} details={details[0]} skills={skills[0]} skillsDetails={skillsDetails[0]} enabled={true} link={link[0]}></Blockmodel>
                <Blockmodel portraitOrLandscape={portraitOrLandscape} imgPath={"/data/images/portfolio/rob.webp"} activityName={'RobRob - Boutique en ligne de robots et services'} type={'DEVELOPPEMENT WEB'} details={details[2]} skills={skills[2]} skillsDetails={skillsDetails[2]} enabled={true} link={link[2]}></Blockmodel>
                <Blockmodel portraitOrLandscape={portraitOrLandscape} imgPath={"/data/images/portfolio/tatouage.webp"} activityName={'InkByNoux - Tatoueur'} type={'DEVELOPPEMENT WEB'} details={details[1]} skills={skills[1]} skillsDetails={skillsDetails[1]} enabled={true} link={link[1]}></Blockmodel>
                <Blockmodel portraitOrLandscape={portraitOrLandscape} imgPath={"/data/images/portfolio/robTrailer.webp"} activityName={'Prochainement'} type={'DEVELOPPEMENT ROBOTIQUE'} details={details[3]} skills={skills[3]} skillsDetails={skillsDetails[3]} enabled={false} link={link[3]}></Blockmodel>
            </PortfolioContainer>

            <Span $portraitOrLandscape={portraitOrLandscape}></Span>

            

        </GlobalContainer>
        <DivCutSection $IsSpecialSocialBrowser={IsSpecialSocialBrowser}>
            <Pcut><b>Un interlocuteur unique pour faciliter nos échanges !</b></Pcut>
        </DivCutSection>
        </div>)
});

export default WorkComponent
