import styled from 'styled-components'
import React, { useState, useRef} from 'react';
import colors from "../../utils/style/color"
import BlockPresentationIconWeb from "../blockIcon/BlockPresentationIconWeb"
import '../../utils/style/lineEffect.scss'
import BouncingBubbles from "../specialComponent/BouncingBubbles"

const ImageContainer = styled.div`

  position: relative; /* Create a positioning context for absolute positioning */
  display: flex;
  width: 100vw;
`



// flex container global
const GlobalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100vw;
    color: white;
`

// flex container global
const GlobalContainerWeb = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

`

const TextTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: ${colors.TextBackground};
    width: 100vw;
`

const TextTitleContainerSub = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
`

const H1 = styled.h1`
    position: relative;
    font-size: 2.5rem;
    letter-spacing: 0.4rem;
    font-weight: bold;
    text-align: center;
    padding-top: 3.5rem;
    text-shadow:  0 0 7px #fff,0 0 10px rgb(230, 130, 42),0 0 13px rgb(230, 130, 42),0 0 16px rgb(230, 130, 42),0 0 20px rgb(230, 130, 42),0 0 25px rgb(230, 130, 42),0 0 27px rgb(230, 130, 42),0 0 30px rgb(230, 130, 42);
    @media (max-width: 800px){
        font-size: 2rem;
        letter-spacing: 0.3rem;
    }

    @media (min-width: 1800px){
        font-size: 4rem;
    }
    @media (min-width: 2800px){
        font-size: 8rem;
    }
`

const H2 = styled.h2`
    font-size: 2rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media (max-width: 800px){
        margin-left: 0.8rem;
        margin-right: 0.8rem;
    }

    @media (min-width: 1800px){
        font-size: 2.5rem;
    }
    @media (min-width: 2800px){
        font-size: 5rem;
    }
`

const ContainerDIVBlock = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;

    @media (max-width: 800px){
        flex-direction:column;
    }

    @media (min-width: 1800px){
        & p {font-size:2rem;}
    }
    @media (min-width: 2800px){
        & p {font-size:4rem;}
    }
`

const ContainerDIVBlockSub1 = styled.div`
    display:flex;
    flex-direction:column;

    @media (max-width: 800px){
        flex-direction:column;
    }

    @media (min-width: 1800px){
        & p {font-size:2rem;}
    }
    @media (min-width: 2800px){
        & p {font-size:4rem;}
    }
`

const ContainerDIVBlockSub2 = styled(ContainerDIVBlockSub1)`
        margin-left: 4rem;

        @media (max-width: 800px){
            margin-left: 0rem;
    }

`

const P1 = styled.div`
    font-size: 1.3rem;
    font-weight: 300;
    text-align: center;
    margin-top: 2rem;
    width: 40vw;
    margin-left: auto; 
    margin-right: auto;

    @media (max-width: 800px){
        width: 100%;
        max-width: 80%; 
        margin-bottom: 1.5rem;
        margin-top:0rem;
    }

        @media (min-width: 1800px){
        font-size: 1.4rem;
    }
    @media (min-width: 2800px){
        font-size: 3rem;
    }
`

const P2 = styled(P1)`
    @media (max-width: 800px){
        margin-top: 0rem;
    }

`

const ArrowDIV = styled.div`
    position: relative;
    cursor: pointer;

    & span {
        display: block;
        width: 1rem;
        height: 1rem;
        border-bottom: 0.15rem solid;
        border-right: 0.15rem solid;
        border-color: ${(props) => props.$arrowsColor};
        transform: rotate(45deg);
        margin: -10px;
        animation: animate 2s infinite;
        
        &:nth-child(2) {
            animation-delay: -0.2s;
        }

        &:nth-child(3) {
            animation-delay: -0.4s;
        }

        }
    
        @keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}
`

const ServicesWebComponent = React.forwardRef((props,ref) => {
    
    

    const h1Ref = useRef(null);


    const webExp = ["Html/Css","Javascript", "ReactJs", "MongoDB/Sql", "ThreeJs", "NextJs", "C#"]

    const [sizetmp, setSizetmp] = useState(() =>{
        if (window.innerWidth >2800){
            return 275;}
        else if (window.innerWidth >1800){
            return 150;}
        else{
            return 90;}
    })

    return (
    
    <div ref={h1Ref}>

    <GlobalContainer ref={ref}> 

            <div className="lineLeft" style={{zIndex:"1"}}></div>
            <div className="lineRight" style={{zIndex:"1"}}></div>

        <ImageContainer>

            
            <GlobalContainerWeb>
                
                <TextTitleContainer>
                    <TextTitleContainerSub>
                        <H1>DÉVELOPPEMENT WEB</H1>
                        <H2 style={{color: colors.TextTitleWebPart}}>Création de sites vitrines <br></br>et d'applications web sur-mesure</H2>

                       

                            <ContainerDIVBlock>

                                <ContainerDIVBlockSub1>

                                    <P1>
                                        <p style={{marginBottom:"0.5rem"}}><b><span>«</span><span style={{color: colors.TextTitleWebPart}}>Gestion de vos projets Web</span> pour la création de sites vitrines sur-mesure et d'applications Web.<span>»</span></b></p>
                                    </P1>

                                    <P2>
                                        <p style={{marginBottom:"0.5rem"}}><b><span>«</span><span style={{color: colors.TextTitleWebPart}}>Des tarifs réduits comparés à ceux d'une agence web et un interlocuteur unique</span> pour la création d'un site web de qualité !<span>»</span></b></p>
                                    </P2>

                                </ContainerDIVBlockSub1>

                                <ContainerDIVBlockSub2>

                                    <P1>
                                        <p style={{marginBottom:"0.5rem"}}><b><span>«</span><span style={{color: colors.TextTitleWebPart}}>A la pointe de la technologie</span>, mes applications sont codées intégralement à la main pour des performances optimales !<span>»</span></b></p>
                                    </P1>

                                    <P2>
                                        <p style={{marginBottom:"0.5rem"}}><b><span>«</span><span style={{color: colors.TextTitleWebPart}}>Conception d'applications web 3D</span>, au plus proche de l'innovation web !<span>»</span></b></p>
                                    </P2>

                                </ContainerDIVBlockSub2>


                            </ContainerDIVBlock>

                            <BlockPresentationIconWeb/>


                    </TextTitleContainerSub>
                </TextTitleContainer>

                

                <TextTitleContainer style={{paddingBottom:"3.5rem", paddingTop:"2rem"}}>
                    <H2 style={{color: colors.TextTitleWebPart, marginTop:"1rem", marginBottom:"2rem"}}>Des compétences web à votre service</H2>
                    <ArrowDIV $arrowsColor = {colors.TextTitleWebPart}>
                        <span></span>
                        <span></span>
                        <span></span>
                    </ArrowDIV>
                </TextTitleContainer>

                <BouncingBubbles sizetmp={sizetmp} WebOrRob={true} expContent={webExp} backgroundDiv={colors.BackgroundTitleWebPart} BubblePart={colors.BubbleWebPart}></BouncingBubbles>

            </GlobalContainerWeb>

        </ImageContainer>

        </GlobalContainer>
        </div>
        );
});

export default ServicesWebComponent
