import styled from 'styled-components'
import "../utils/style/ButtonWaterEffect.css"
import { NavLink } from 'react-router-dom'

const ContainerGlobal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100vw;
    height: 100vh;
    background-image: url("/data/images/error/error.webp");
    background-size: cover;
    color: white;
`

const ContainerContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: rgba(68,108,177,0.6);
    padding: 1rem;
`

function Error() {

    return <ContainerGlobal> 

        <ContainerContent>

            <h1 style={{fontSize:"3rem", marginBottom:"2rem"}}>ERREUR 404.</h1>

            <p style={{fontSize:"1.5rem", textAlign:"center"}}>Il semblerait que vous vous êtes perdu dans les profondeurs de l'océan.</p>

            <NavLink to="/#home">

            <div style={{position:"relative", width:"50vw",height:"30vh"}}>

                <div class="box">
                    <span>Remonter à la surface</span>
                    <i></i>
                </div>

            </div>

            </NavLink>
            
        </ContainerContent>

        </ContainerGlobal>
}

export default Error
