import styled from 'styled-components'
import React, {useState} from 'react';
import colors from "../../utils/style/color"
import BlockPresentationIconRob from "../blockIcon/BlockPresentationIconRob"
import '../../utils/style/lineEffect.scss'
import BouncingBubbles from "../specialComponent/BouncingBubbles"

// flex container global
const GlobalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    width: 100vw;
    color: white;
`

// flex container global
const GlobalContainerRob = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;

`

const TextTitleContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    background-color: ${colors.TextBackground};
    width: 100vw;
`

const TextTitleContainerSub = styled.div`
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
`

const H1 = styled.h1`
    position: relative;
    font-size: 2.5rem;
    letter-spacing: 0.4rem;
    font-weight: bold;
    text-align: center;
    padding-top: 3.5rem;

    text-shadow:  0 0 7px #fff,0 0 10px rgb(144, 200, 253),0 0 13px rgb(144, 200, 253),0 0 16px rgb(144, 200, 253),0 0 20px rgb(144, 200, 253),0 0 25px rgb(144, 200, 253),0 0 27px rgb(144, 200, 253);
/*     text-shadow: 0 0 7px #fff,0 0 10px rgb(144, 200, 253),0 0 13px rgb(144, 200, 253),0 0 16px rgb(144, 200, 253),0 0 20px rgb(144, 200, 253);
 */    @media (max-width: 800px){
        font-size: 2rem;
        letter-spacing: 0.3rem;
    }

    @media (min-width: 1800px){
        font-size: 4rem;
    }
    @media (min-width: 2800px){
        font-size: 8rem;
    }
`


const H2 = styled.h2`
    font-size: 2rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 2rem;

    @media (max-width: 800px){
        margin-left: 0.8rem;
        margin-right: 0.8rem;
    }

    @media (min-width: 1800px){
        font-size: 2.5rem;
    }
    @media (min-width: 2800px){
        font-size: 5rem;
    }
`

const P1 = styled.div`
    font-size: 1.3rem;
    font-weight: 300;
    text-align: justify;
    margin-top: 2rem;
    width: 40vw;
    margin-left: auto; 
    margin-right: auto;

    @media (max-width: 800px){
        width: 100%;
        max-width: 80%; 
        margin-bottom: 1.5rem;
        margin-top:0rem;
    }

    @media (min-width: 1800px){
        font-size: 1.4rem;
    }
    @media (min-width: 2800px){
        font-size: 3.2rem;
    }
`

const P2 = styled(P1)`
    @media (max-width: 800px){
        margin-top: 0rem;
    }

`

const ContainerDIVBlock = styled.div`
    display:flex;
    flex-direction:row;
    justify-content: center;

    @media (max-width: 800px){
        flex-direction:column;
    }

    @media (min-width: 1800px){
        & p {font-size:2rem;}
    }
    @media (min-width: 2800px){
        & p {font-size:4rem;}
    }

`

const ContainerDIVBlockSub1 = styled.div`
    display:flex;
    flex-direction:column;

    @media (max-width: 800px){
        flex-direction:column;
    }

    @media (min-width: 1800px){
        & p {font-size:2rem;}
    }
    @media (min-width: 2800px){
        & p {font-size:4rem;}
    }
`

const ContainerDIVBlockSub2 = styled(ContainerDIVBlockSub1)`
        margin-left: 4rem;

        @media (max-width: 800px){
            margin-left: 0rem;
    }
`

const ArrowDIV = styled.div`
    position: relative;
    cursor: pointer;

    & span {
        display: block;
        width: 1rem;
        height: 1rem;
        border-bottom: 0.15rem solid;
        border-right: 0.15rem solid;
        border-color: ${(props) => props.$arrowsColor};
        transform: rotate(45deg);
        margin: -10px;
        animation: animate 2s infinite;
        
        &:nth-child(2) {
            animation-delay: -0.2s;
        }

        &:nth-child(3) {
            animation-delay: -0.4s;
        }

        }
    
        @keyframes animate {
    0% {
        opacity: 0;
        transform: rotate(45deg) translate(-20px, -20px);
    }
    50% {
        opacity: 1;
    }
    100% {
        opacity: 0;
        transform: rotate(45deg) translate(20px, 20px);
    }
}
`

const ServicesRobComponent = React.forwardRef((props,ref) => {    

    const RobExp = ["Python", "C++", "2D/3D processing", "Système embarqué", "ROS", "Contrôleur robotique", "Interface Utilisateur UI"]

    const [sizetmp, setSizetmp] = useState(() =>{
        if (window.innerWidth >2800){
            return 275;}
        else if (window.innerWidth >1800){
            return 150;}
        else{
            return 90;}
    })

    return (
    
    <div>

    <GlobalContainer ref={ref}> 

            <div className="lineLeft" style={{zIndex:"1"}}></div>
            <div className="lineRight" style={{zIndex:"1"}}></div>
            
            <GlobalContainerRob>

            <TextTitleContainer>
                <TextTitleContainerSub>

                    <H1>DÉVELOPPEMENT ROBOTIQUE</H1>
                    <H2 style={{color: colors.TextTitleRobPart}}>Programmation de vos applications robotique</H2>

                    <ContainerDIVBlock>

                        <ContainerDIVBlockSub1>

                            <P1>
                                <p style={{marginBottom:"0.5rem"}}><b><span>«</span><span style={{color: colors.TextTitleRobPart}}>Développement de modules logiciels robotique</span> sur des thématiques spécifiques et intégration de système.<span>»</span></b></p>
                            </P1>

                            <P2>
                                <p style={{marginBottom:"0.5rem"}}><b><span>«</span>Je propose des services <span style={{color: colors.TextTitleRobPart}}>de conseil et d'expertise en robotique</span>.<span>»</span></b></p>
                            </P2>

                        </ContainerDIVBlockSub1>

                        <ContainerDIVBlockSub2>

                            <P1>
                            <p style={{marginBottom:"0.5rem"}}><b><span>«</span><span style={{color: colors.TextTitleRobPart}}>Méthodologie Recherche et Développement</span>, je m'inspire des recherches existantes pour créer, adapter et/ou améliorer les fonctionnalités robotiques de votre projet<span>»</span></b></p>
                            </P1>

                            <P2>
                            <p style={{marginBottom:"0.5rem"}}><b><span>«</span><span style={{color: colors.TextTitleRobPart}}>Travaux à visée mécanique et électronique</span> mais mon coeur de métier et ma passion résident dans le développement numérique<span>»</span></b></p>
                            </P2>

                        </ContainerDIVBlockSub2>

                    </ContainerDIVBlock>

                    <BlockPresentationIconRob/>
                    


                </TextTitleContainerSub>
            </TextTitleContainer>

            

            <TextTitleContainer style={{paddingBottom:"3.5rem", paddingTop:"2rem"}}>
                <H2 style={{color: colors.TextTitleRobPart, marginTop:"1rem", marginBottom:"2rem"}}>Votre support technique pour des solutions logicielles robotiques</H2>
                <ArrowDIV $arrowsColor = {colors.TextTitleRobPart}>
                    <span></span>
                    <span></span>
                    <span></span>
                </ArrowDIV>
            </TextTitleContainer>

            <BouncingBubbles sizetmp={sizetmp} WebOrRob={false} expContent={RobExp} backgroundDiv={colors.BackgroundTitleRobPart} BubblePart={colors.BubbleRobPart}></BouncingBubbles>

        </GlobalContainerRob>

        </GlobalContainer>
        </div>
        );
});

export default ServicesRobComponent
