import { createGlobalStyle } from 'styled-components'
import { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'

// eslint-disable-next-line no-use-before-define
export const StyledGlobalStyle = createGlobalStyle`
* {

  ::-webkit-scrollbar:horizontal{
    display: none;
  }

  ::-webkit-scrollbar {
    width: 6px;
    background-color: transparent !important;
    
  }

  ::-webkit-scrollbar-track {
  background-color: transparent !important;
}

  ::-webkit-scrollbar-thumb {
    background-color: rgb(26, 26, 26);
    border-radius: 16px;
    border: 1px solid #fff;
  }

/* set button(top and bottom of the scrollbar) */
body::-webkit-scrollbar-button {
    display:none;
}

  margin: 0;
  padding: 0;
  outline: 0;
  font-size: 100%;
  vertical-align: baseline;
  background: transparent;
  font-family: 'Roboto';

    }

    body:after{
      content:"";
      position:fixed; /* stretch a fixed position to the whole screen */
      top:0;
      height: ${(props) => props.$IsSpecialSocialBrowser ? "700px" : "100vh"};/* fix for mobile browser address bar appearing disappearing */
      left:0;
      right:0;
      z-index:-1; /* needed to keep in the background */
      background: ${(props) => props.$changeBackgroungImage ? "url('/data/images/fixedBackEffect/realisation.webp') center center no-repeat" : "url('/data/images/fixedBackEffect/contact.webp') center center no-repeat"};;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
}

html, body {
    max-width: 100%;
    overflow-x: hidden;
/*     position: absolute;
    top: 0.5px;
    left: 0;
    right: 0;
    bottom: 0.5px;
    
    overflow-y: auto;  */
}

main{

}

`

function GlobalStyle() {

  const { IsSpecialSocialBrowser, changeBackgroungImage } = useContext(ThemeContext)


  return (

  <StyledGlobalStyle $IsSpecialSocialBrowser={IsSpecialSocialBrowser} $changeBackgroungImage={changeBackgroungImage}/>

  )

}

export default GlobalStyle