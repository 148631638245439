import React from 'react'
import ReactDOM from 'react-dom/client'
import { BrowserRouter as Router, Route, Routes, Outlet } from 'react-router-dom'
import Home from './pages/Home'
import Header from './components/header/Header'
import ScrollToAnchor from './utils/utils_function/ScrollToAnchor'
import Footer from "./components/footer/Footer"
import Error from './pages/Error'
import MentionsLegales from './pages/MentionsLegales' 


import GlobalStyle from './utils/style/GlobalStyle'

import {ThemeProvider} from "./utils/context/ThemeProvider"

// LOGIC PART

const PageLayout = () => (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
/*   <React.StrictMode> */
    <Router>

    <ScrollToAnchor></ScrollToAnchor>

    <ThemeProvider>

      <GlobalStyle/>
      

        <Routes>
            <Route element={<PageLayout />}>
            <Route path="/" element={<Home/>}/>
            <Route path="/mentions-legales" element={<MentionsLegales/>}/>
            </Route>
            
            <Route path="*" element={<Error />}/>
        </Routes>


    </ThemeProvider>

    </Router>
/*   </React.StrictMode> */
)