import styled, { css, keyframes } from 'styled-components';

const ballKeyframes = keyframes`
  0% {
    left: -20%;
    top: 10%;
  }
  10% {
    left: 10%;
    top: -35%;
  }
  25% {
    left: 45%;
    top: -50%;
  }
  40% {
    left: 80%;
    top: -20%;
  }
  50% {
    left: 98%;
    top: 18%;
  }
  60% {
    left: 80%;
    top: 50%;
  }
  75% {
    left: 45%;
    top: 80%;
  }
  90% {
    left: 0%;
    top: 60%;
  }
  100% {
    left: -20%;
    top: 10%;
  }
`;

const Button = styled.button`
  position: fixed;
  z-index: 30;
  top: ${(props) => props.$active ?  "50px" : "50px"};
  right: 5%;
  width: ${(props) => props.$active ?  "50px" : "50px"};
  height: ${(props) => props.$active ?  "70px" : "40px"};
  transition: all 300ms cubic-bezier(.61, .01, .42, 1);
  cursor: pointer;
  background: transparent;
  border: 0;
  
  &:active {
    transform: scale(1.1);
  }
  
  &:focus {
    outline: 0;
  }
`;

const Div = styled.div`
  height: 0;
  border: 2px solid #fff;
  width: 32px;
  display: block;
  position: absolute;
  transition: all 300ms cubic-bezier(.61, .01, .42, 1);
  background: #fff;
  
  &:nth-child(1) {
    top: 0;
  }
  
  &:nth-child(2) {
    top: 10px;
  }
  
  &:nth-child(3) {
    top: 22px;
  }
  
  &:before {
    opacity: 0;
    animation: ${ballKeyframes} 1.5s linear infinite;
    content: '';
    border: 1px solid #fff;
    display: block;
    position: relative;
    top: 0.25px;
  }
  
  ${props =>
    props.$active &&
    css`
    border: 2px solid #fff;
    height: 14px;
    border-radius: 50%;
    margin-left: -1px;
    margin-top: 2px;
    width: 40px;
    top: 0;
    background: transparent;
  `}
  
  ${props =>
    props.$active &&
    css`
    &:nth-child(3):before {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background: #90c8fd;
    }
  `}
  
  ${props =>
    props.$active &&
    css`
    &:nth-child(1) {
      transform: rotate(-33deg);
      top:0px;
    }
  `}
  
  ${props =>
    props.$active &&
    css`
    &:nth-child(2) {
      transform: rotate(90deg);
      top:0px
    }
  `}
  
  ${props =>
    props.$active &&
    css`
    &:nth-child(3) {
      transform: rotate(33deg);
      top:0px;
    }
  `}
  
  ${props =>
    props.$active &&
    css`
    &:nth-child(3):before {
      opacity: 1;
      transition: opacity 600ms cubic-bezier(.61, .01, .42, 1);
    }
  `}
`;

const AtomHamburgerStyle = ({active, setActive}) => {

  return (
    <Button $active={active}
      onClick={() => setActive(!active)}
    >
      <Div $active={active}></Div>
      <Div $active={active}></Div>
      <Div $active={active}></Div>
    </Button>
  );
};

export default AtomHamburgerStyle;