import styled from 'styled-components'
import colors from "../../utils/style/color"
import { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'

// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    justify-content:center;
    flex-direction: column;
    margin:1.5rem 1rem;
    min-width: 15vw;
    min-height: ${(props) => props.$IsSpecialSocialBrowser ? "100px" : "10vh"};
    flex-wrap: wrap; /* Allow items to wrap to the next column */
`

const BlockContainerLogo = styled.a`
    display: flex;
    background-color: ${colors.BubbleContactBackgroundLow};
    border-radius:50%;
    cursor:pointer;
    transition: all 0.2s ease-in-out;

    &:hover{
        background-color: ${(props) => (props.$domaine === "Web") ? "rgb(230, 130, 42)" : "rgb(144, 200, 253)"};
    }

`

const Img = styled.img`
    position:relative;
    width: 20px;
    height: 20px;
    margin:1rem;

    @media (min-width: 1800px){
        width: 35px;
        height: 35px;
    }
    @media (min-width: 2800px){
        width: 70px;
        height: 70px;
        margin:2rem;
    }

`

const H1 = styled.h1`
    font-size: 0.75rem;
    text-align: center;
    margin-top: 1rem;
    max-width: 100%; // Add this line

    @media (min-width: 1800px){
        font-size: 1rem;
    }
    @media (min-width: 2800px){
        font-size: 2rem;
    }
`
  
const ContactBlockIcon = ({domaine, imgPath, text, link}) => {

    const { IsSpecialSocialBrowser } = useContext(ThemeContext)

    return (

        <FlexContainerDIV $IsSpecialSocialBrowser={IsSpecialSocialBrowser}>

                    <BlockContainerLogo href={link} $domaine={domaine}>
                        <Img src={imgPath} alt="images contact, horaire, mail,tel...etc"></Img>
                    </BlockContainerLogo>

                    <H1>{text}</H1>

        </FlexContainerDIV>

    )
  }
  
export default ContactBlockIcon;