import React, { useState, useEffect } from 'react';
import Model3dComponent from '../Model3d/Model3dComponent'
import styled, { keyframes } from 'styled-components';
import { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'

// flex container global
const GlobalContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    position: relative;
    background: url('/data/body.webp');
  background-size: cover;
    width: 100vw;
    height: ${(props) => props.$IsSpecialSocialBrowser ? "700px" : "100lvh"};
`

const DIVScrollOnTouchDevice = styled.div`
z-index:-2;

@media (pointer:coarse) {
    z-index:11;
}
`

// Loader Container
const LoaderContainer = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2rem;
  opacity: ${(props) => props.$opacityTransition ? "1" : "0"};
  transition: all 1s;

`;

// Keyframes for animation
const animateWater = keyframes`
  0% {
    background-position: left 0px top 10px;
  }
  40% {
    background-position: left 800px top -50px;
  }
  80% {
    background-position: left 1800px top -50px;
  }
  100% {
    background-position: left 2400px top 10px;
  }
`;

// Keyframes for animation
const animateWaterMobile = keyframes`
  0% {
    background-position: left 0px top -30px;
  }
  40% {
    background-position: left 800px top -70px;
  }
  80% {
    background-position: left 1800px top -70px;
  }
  100% {
    background-position: left 2400px top -30px;
  }
`;

// Loader h1
const LoaderH1 = styled.h1`
  margin: 0;
  padding: 0;
  text-transform: uppercase;
  font-size: 10rem;
  color: rgba(5, 16, 27, 0.4);
  background-image: url("/data/images/intro/WaveWithFish.webp");
  background-clip: text;
  animation: ${animateWater} 5s linear infinite;

  @media (max-width: 810px){
    font-size: 5rem;
    animation: ${animateWaterMobile} 5s linear infinite;
    }

`;


const IntroComponent = React.forwardRef((props,ref) => {

    const [isModelLoaded, setModelLoaded] = useState(false);
    const [TriggertimeoutState, setTriggerTimeoutState] = useState(false);
    const[opacityTransition, setOpacityTransition] = useState(1);

    // get height 3d part to avoid resize bug with adress bar
    const [height3d, setHeight3d] = useState(0);
    
    const { IsSpecialSocialBrowser } = useContext(ThemeContext)
    
    useEffect(() => {

        const timeoutId = setTimeout(() => {

          setTriggerTimeoutState(true);
        }, 2000);

        return () => clearTimeout(timeoutId);
        }, []);

        useEffect(() => {

            if (isModelLoaded === true){
                setOpacityTransition(0);
            }

            }, [isModelLoaded]);


    useEffect(() => {
        setHeight3d(ref.current.offsetHeight );
        }, [ref]);

        const handleModelLoad = () => {
        setModelLoaded(true);
        };

    return (<GlobalContainer ref={ref} $IsSpecialSocialBrowser={IsSpecialSocialBrowser}> 


        <LoaderContainer $opacityTransition={opacityTransition} className="loader">
            <LoaderH1>LOADING</LoaderH1>
        </LoaderContainer>


        <div id="three-container" style={{ position:"relative" }}>

        {(height3d !== 0 && TriggertimeoutState) &&
        <div>
        <Model3dComponent onModelLoad={handleModelLoad} height3d={height3d}></Model3dComponent>
        </div>
        
        }
        
        <DIVScrollOnTouchDevice style={{display:"flex", position:"absolute", top:"0", left:"0", width:"100vw", height:"100lvh", opacity:"0"}}></DIVScrollOnTouchDevice>

        </div>


        </GlobalContainer>);
});

export default IntroComponent
