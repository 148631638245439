const colors = {


    PortfolioBackgroundText : 'rgb(38, 38, 38)',
    ContactMeContainer : 'rgb(35, 35, 35)',
    FooterBackground : 'rgb(24, 24, 24)',
    TextBackgroundLow : 'rgb(35, 35, 35)',
    BubbleContactBackgroundLow : 'rgb(60, 60, 60)',
    BubbleContactBackgroundLowHover : 'rgb(85, 85, 85)',
    TextBackground : 'rgb(28, 28, 28)',
    TextBackgroundTranspa1 : 'rgb(32, 32, 32,0)',
    TextBackgroundTranspa2 : 'rgb(32, 32, 32,0.5)',
    TextBackgroundTranspa3 : 'rgb(32, 32, 32,0.4)',
    TextBackgroundTranspa4 : 'rgb(32, 32, 32,0.3)',
    TextBackgroundTranspa5 : 'rgb(32, 32, 32,0.7)',

    TextTitleWebPart : 'rgb(230, 130, 42)',
    TextTitleRobPart : 'rgb(144, 200, 253)',

    BackgroundTitleWebPart : 'rgba(230, 130, 42,0.15)',
    BackgroundTitleRobPart : 'rgba(144, 200, 253,0.1)',

    BubbleWebPart : 'rgba(230, 130, 42,0.6)',
    BubbleRobPart : 'rgba(144, 200, 253,0.8)',
    
    HeaderChoice : '#5aafff',
    HeaderHover : '#90c8fd',

    contactBackground : "rgb(60, 60, 60)",

}

export default colors