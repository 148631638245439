import styled from 'styled-components'
import "../utils/style/ButtonWaterEffect.css"
import colors from "../utils/style/color"

const ContainerGlobal = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100vw;
    color: white;
    text-align: justify;
`

const ContainerTitle = styled.div`
    display:flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    background-color: ${colors.ContactMeContainer};
    font-size: 3rem;
    letter-spacing: 0.3rem;
    padding: 10vh 1rem 1rem 1rem;
`



const ContainerContent = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background-color: ${colors.TextBackground};
    flex-direction: column;
    padding: 2rem 20vw 2rem 20vw;
`

const ContainerParaph = styled.div`
    width: 50vw;
    padding: 1rem 1rem 1rem 1rem;

    @media (max-width: 810px){
        width: 80vw;
    }

`

const H2 = styled.h2`
    font-size: 1.5rem;
    margin-bottom: 1rem;


`

const P = styled.p`
    margin-bottom: 0.5rem;
`

function MentionsLegales() {

    return <ContainerGlobal id="mentions"> 

            <ContainerTitle>
                <h1 style={{textAlign:"center"}}>Mentions légales</h1>
            </ContainerTitle>

            <ContainerContent>

                <ContainerParaph>
                    <H2>1. Présentation du site - Propriétaire et éditeur.</H2>
                    <P><b>Créateur</b> : Sébastien PETIT<br></br></P>
                    <P><b>Propriétaire</b> : Sébastien PETIT<br></br></P>
                    <P><b>Responsable publication</b> : Sébastien PETIT<br></br></P>
                    <P><b>Adresse</b> : SebaDev, Montpellier, 34000, France<br></br></P>
                    <P><b>Titulaire du CAPE</b> : n°C1144, échéant le 31/01/2025<br></br></P>
                    <P><b>Adresse PCE</b> : Tech'Indus D - 645 Rue Mayor de Montricher - 13290 Aix en Provence<br></br></P>
                    <P><b>SIRET PCE</b> : 423 194 307 00033<br></br></P>
                    <P><b>Site</b> : <b><a style={{textDecoration:"none", color:'rgb(180,180,180)', fontSize:"0.95rem"}} href="https://sebastien-petit-dev.com/">https://sebastien-petit-dev.com/</a></b><br></br></P>
                    <P><b>Hebergeur</b> : Netlify, 512 2nd Street, Suite 200 San Francisco, CA 94107<br></br></P>
                </ContainerParaph>

                <ContainerParaph>
                    <H2>2. Conditions générales d'utilisation du site et des services proposés.</H2>
                    <P>L'utilisation du site implique l'acceptation pleine et entière des conditions générales d'utilisation ci-après décrites. Ces conditions d'utilisation sont susceptibles d'être modifiées ou complétées à tout moment, les utilisateurs du site sont donc invités à les consulter de manière régulière. </P>
                    <P>Ce site est normalement accessible à tout moment aux utilisateurs. Une interruption pour raison de maintenance technique peut être toutefois décidée par Sébastien PETIT, qui s'efforcera alors de communiquer préalablement aux utilisateurs les dates et heures de l'intervention. </P>
                    <P>Le site <b><a style={{textDecoration:"none", color:'rgb(180,180,180)', fontSize:"0.95rem"}} href="https://sebastien-petit-dev.com/">https://sebastien-petit-dev.com/</a></b> est mis à jour régulièrement par Sébastien PETIT. De la même façon, les mentions légales peuvent être modifiées à tout moment : elles s'imposent néanmoins à l'utilisateur qui est invité à s'y référer le plus souvent possible afin d'en prendre connaissance. </P>
                </ContainerParaph>

                <ContainerParaph>
                    <H2>3. Description des services fournis.</H2>
                    <P>Le site <b><a style={{textDecoration:"none", color:'rgb(180,180,180)', fontSize:"0.95rem"}} href="https://sebastien-petit-dev.com/">https://sebastien-petit-dev.com/</a></b> a pour objet de fournir une information concernant l'ensemble des activités de la société.</P>
                    <P>Sébastien PETIT s'efforce de fournir sur le site des informations aussi précises que possible. Toutefois, il ne pourra être tenu responsable des omissions, des inexactitudes et des carences dans la mise à jour, qu'elles soient de son fait ou du fait des tiers partenaires qui lui fournissent ces informations. </P>
                    <P>Toutes les informations indiquées sur le site sont données à titre indicatif, et sont susceptibles d'évoluer. Par ailleurs, les renseignements figurant sur le site ne sont pas exhaustifs. Ils sont donnés sous réserve de modifications ayant été apportées depuis leur mise en ligne. </P>
                </ContainerParaph>

                <ContainerParaph>
                    <H2>4. Limitations contractuelles sur les données techniques.</H2>
                    <P>Le site utilise la technologie JavaScript, React, NodeJS et Express.</P>
                    <P>Le site Internet ne pourra être tenu responsable de dommages matériels liés à l'utilisation du site. De plus, l'utilisateur du site s'engage à accéder au site en utilisant un matériel récent, ne contenant pas de virus et avec un navigateur de dernière génération mis-à-jour. </P>
                </ContainerParaph>

                <ContainerParaph>
                    <H2>5. Propriété intellectuelle et contrefaçons.</H2>
                    <P>Sébastien PETIT est propriétaire des droits de propriété intellectuelle ou détient les droits d'usage sur tous les éléments accessibles sur le site, notamment les textes, images, graphismes, logo, icônes, sons, logiciels. </P>
                    <P>Toute reproduction, représentation, modification, publication, adaptation de tout ou partie des éléments du site, quel que soit le moyen ou le procédé utilisé, est interdite, sauf autorisation écrite préalable de : Sébastien PETIT. </P>
                    <P>Toute exploitation non autorisée du site ou de quelconque éléments qu'il contient sera considérée comme constitutive d'une contrefaçon et poursuivie conformément aux dispositions des articles L.335-2 et suivants du Code de Propriété Intellectuelle. </P>
                </ContainerParaph>

                <ContainerParaph>
                    <H2>6. Limitations de responsabilité.</H2>
                    <P>Sébastien PETIT ne pourra être tenu responsable des dommages directs et indirects causés au matériel de l'utilisateur, lors de l'accès au site <b><a style={{textDecoration:"none", color:'rgb(180,180,180)', fontSize:"0.95rem"}} href="https://sebastien-petit-dev.com/">https://sebastien-petit-dev.com/</a></b>, et résultant soit de l'utilisation d'un matériel ne répondant pas aux spécifications indiquées au point 4, soit de l'apparition d'un bug ou d'une incompatibilité.</P>
                    <P>Sébastien PETIT ne pourra également être tenu responsable des dommages indirects consécutifs à l'utilisation du site. </P>
                </ContainerParaph>

                <ContainerParaph>
                    <H2>7. Gestion des données personnelles.</H2>
                    <P>En France, les données personnelles sont notamment protégées par la loi n° 78-87 du 6 janvier 1978, la loi n° 2004-801 du 6 août 2004, l'article L. 226-13 du Code pénal et la Directive Européenne du 24 octobre 1995. </P>
                    <P>Conformément aux dispositions des articles 38 et suivants de la loi 78-17 du 6 janvier 1978 relative à l'informatique, aux fichiers et aux libertés, tout utilisateur dispose d'un droit d'accès, de rectification et d'opposition aux données personnelles le concernant, en effectuant sa demande écrite et signée, accompagnée d'une copie du titre d'identité avec signature du titulaire de la pièce, en précisant l'adresse à laquelle la réponse doit être envoyée. </P>
                    <P>Aucune information personnelle de l'utilisateur du site <b><a style={{textDecoration:"none", color:'rgb(180,180,180)', fontSize:"0.95rem"}} href="https://sebastien-petit-dev.com/">https://sebastien-petit-dev.com/</a></b> n'est publiée à l'insu de l'utilisateur, échangée, transférée, cédée ou vendue sur un support quelconque à des tiers. Seule l'hypothèse du rachat de Sébastien PETIT et de ses droits permettrait la transmission des dites informations à l'éventuel acquéreur qui serait à son tour tenu de la même obligation de conservation et de modification des données vis à vis de l'utilisateur. </P>
                    <P>Le site n'est pas déclaré à la CNIL car il ne recueille pas d'informations personnelles.</P>
                    <P>Les bases de données sont protégées par les dispositions de la loi du 1er juillet 1998 transposant la directive 96/9 du 11 mars 1996 relative à la protection juridique des bases de données.</P>
                </ContainerParaph>

                <ContainerParaph>
                    <H2>8. Formulaires.</H2>
                    <P>L'Utilisateur devra renseigner tous les champs du formulaire de saisie d'informations sur le site à l'exception des champs affichés comme facultatifs c'est-à-dire non signalés par un astérisque.</P>
                    <P>Toutes les informations signalées par un astérisque sont obligatoires. L'Utilisateur doit y répondre.</P>
                    <P>Tout défaut de réponse aura pour conséquence la non création de compte ou la non validation du formulaire de saisie d'informations.</P>

                </ContainerParaph>

                <ContainerParaph>
                    <H2>9. Liens hypertextes et cookies</H2>
                    <P>Le site <b><a style={{textDecoration:"none", color:'rgb(180,180,180'}} href="https://sebastien-petit-dev.com/">https://sebastien-petit-dev.com/</a></b> contient un certain nombre de liens hypertextes vers d'autres sites, mis en place avec l'autorisation de Sébastien PETIT. Cependant, Sébastien PETIT n'a pas la possibilité de vérifier le contenu des sites ainsi visités, et n'assumera en conséquence aucune responsabilité de ce fait. </P>
                    <P>La navigation sur le site est susceptible de provoquer l'installation de cookie(s) sur l'ordinateur de l'utilisateur. Un cookie est un fichier de petite taille, qui ne permet pas l'identification de l'utilisateur, mais qui enregistre des informations relatives à la navigation d'un ordinateur sur un site. Les données ainsi obtenues visent à faciliter la navigation ultérieure sur le site, et ont également vocation à permettre diverses mesures de fréquentation. </P>
                    <P>Le refus d'installation d'un cookie peut entraîner l'impossibilité d'accéder à certains services. L'utilisateur peut toutefois configurer son ordinateur afin de bloquer tous les cookies.  </P>
                </ContainerParaph>

                <ContainerParaph>
                    <H2>10. Droit applicable et attribution de juridiction.</H2>
                    <P>Tout litige en relation avec l'utilisation du site <b><a style={{textDecoration:"none", color:'rgb(180,180,180'}} href="https://sebastien-petit-dev.com/">https://sebastien-petit-dev.com/</a></b> est soumis aux tribunaux francais compétents statuant selon le droit français. </P>
                </ContainerParaph>

                <ContainerParaph>
                    <H2>11. Les principales lois concernées.</H2>
                    <P>Loi n° 78-87 du 6 janvier 1978, notamment modifiée par la loi n° 2004-801 du 6 août 2004 relative à l'informatique, aux fichiers et aux libertés.</P>
                    <P>Loi n° 2004-575 du 21 juin 2004 pour la confiance dans l'économie numérique.</P>
                </ContainerParaph>

            </ContainerContent>

        </ContainerGlobal>
}

export default MentionsLegales
