import IntroComponent from "../components/home/IntroComponent"
import ServicesWebComponent from "../components/home/ServicesWebComponent"
import ServicesRobComponent from "../components/home/ServicesRobComponent"
import ContactComponent from "../components/home/ContactComponent"
import WorkComponent from "../components/home/WorkComponent"
import AboutComponent from "../components/home/AboutComponent"
import PresComponent from "../components/home/PresComponent"

import { useContext, useEffect , useRef, useState} from 'react'
import { ThemeContext } from '../utils/context/ThemeProvider'



function Home() {

    const Section1Ref = useRef(null);
    const Section2Ref = useRef(null);
    const Section3Ref = useRef(null);
    const Section4Ref = useRef(null);
    const Section5Ref = useRef(null);
    const Section6Ref = useRef(null);
    const Section7Ref = useRef(null);

    const constRefArray = [Section1Ref,Section2Ref,Section3Ref,Section4Ref,Section5Ref,Section6Ref,Section7Ref]

    const { toggleValueOfSetContainerViewer } = useContext(ThemeContext)

    const [firstrender, setFirstrender] = useState(true);


    useEffect(() => {

        if(firstrender){
            onWindowScroll()
            setFirstrender(false)
  
        }

        window.addEventListener( 'scroll', onWindowScroll );

        function onWindowScroll() {

            let stateNavLinkEnabled = [false,false,false,false,false, false, false]
            
            for (let i = 0; i < constRefArray.length; i++) {

                if (constRefArray[i].current) {
                    const rect = constRefArray[i].current.getBoundingClientRect();
                    
                    const viewportHeightPosition = rect.top;
                    const heightElement = constRefArray[i].current.clientHeight
        
                    if((viewportHeightPosition<5) && (viewportHeightPosition > -heightElement + 5)){
                        stateNavLinkEnabled[i] = true;
                        break;
                    };
                }

            }

            const EveryFalseValues = stateNavLinkEnabled.every(valeur => valeur === false);
            
            if (EveryFalseValues){
                stateNavLinkEnabled[0] = true;
            }
            else{
                stateNavLinkEnabled[0] = false;
            }

            toggleValueOfSetContainerViewer(stateNavLinkEnabled)
        }
        

    },[constRefArray, firstrender, toggleValueOfSetContainerViewer])

    return <div> 

            <section id="home">
                <PresComponent ref={Section1Ref}/>
            </section>

            <section id="submarine3d">
                <IntroComponent ref={Section2Ref}/>
            </section>

            <section id="servicesWeb">
                <ServicesWebComponent ref={Section3Ref}/>
            </section>

            <section id="servicesRob">
                <ServicesRobComponent ref={Section4Ref}/>
            </section>

            <section id="work">
                <WorkComponent ref={Section5Ref}/>
            </section>

            <section id="contact">
                <ContactComponent ref={Section6Ref}/>
            </section>

            <section id="about">
                <AboutComponent ref={Section7Ref}/>
            </section>




        </div>
}

export default Home