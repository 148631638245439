import styled from 'styled-components'
import colors from "../../utils/style/color"
import { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'


// Global flex container
const FlexContainerDIV = styled.div`
    display: flex;
    position: relative;
    align-items: center;
    flex-direction: column;
    background-color: ${colors.TextBackgroundLow};
    border-radius: 15px;
    margin: 1.5rem 1rem;
    padding: 1rem;
    width: 25vw;
    height: ${(props) => props.$IsSpecialSocialBrowser ? '300px' : '35vh'};
    transition: transform 0.3s;

    &:hover{
        transform: scale(1.1);
    }
    

    @media (max-width: 800px){
        width: 60vw;
        height: ${(props) => props.$IsSpecialSocialBrowser ? '250px' : '30vh'};
    }

    @media (max-height: 450px){
        width: 40vw;
        height: ${(props) => props.$IsSpecialSocialBrowser ? '350px' : '40vh'};
    }

    @media(min-width: 850px) and (max-width : 1150px) and (min-height: 700px) and (max-height : 1100px)   {
        width: 30vw;
        height: ${(props) => props.$IsSpecialSocialBrowser ? '250px' : '30vh'};
    }

    @media(min-width: 750px) and (max-width : 1050px) and (min-height: 990px) and (max-height : 1400px)   {
        width: 20vw;
        height: ${(props) => props.$IsSpecialSocialBrowser ? '250px' : '30vh'};
    }

    @media (min-width: 2800px){
        margin: 2.5rem 2rem;
    }
`
const BlockContainerLogo = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 20%;
`

const BlockContainerTitle = styled(BlockContainerLogo)`
height: 30%;
`

const BlockContainerText = styled(BlockContainerLogo)`
height: 50%;
`

const Img = styled.img`
    position:relative;
    width: 50px;
    height: 50px;
    @media (min-width: 2800px){
        width: 125px;
        height: 125px;
    }
`

const P = styled.p`
 text-align: center;
 color: white;
 font-size: 0.8rem;

 @media (min-width: 1800px){
        font-size: 1.2rem;
    }
    @media (min-width: 2800px){
        font-size: 2.4rem;
    }

`

const H1 = styled.h1`
font-size: 1.2rem;
text-align: center;

@media (min-width: 1800px){
        font-size: 1.7rem;
    }
    @media (min-width: 2800px){
        font-size: 3.4rem;
    }

`
  
const BlockIcon = ({imgPath, text, title}) => {

    const { IsSpecialSocialBrowser } = useContext(ThemeContext)

    return (

        <FlexContainerDIV $IsSpecialSocialBrowser={IsSpecialSocialBrowser}>

                    <BlockContainerLogo>
                        <Img src={imgPath} alt="images logo web develloppement et robotique"></Img>
                    </BlockContainerLogo>

                    <BlockContainerTitle>
                        <H1>{title}</H1>
                    </BlockContainerTitle>

                    <BlockContainerText>
                        <P>{text}</P>
                    </BlockContainerText>

        </FlexContainerDIV>

    )
  }
  
export default BlockIcon;