import HeaderComputer from "./HeaderComputer"
import HeaderMobile from "./HeaderMobile"
import { useState, useEffect } from 'react'

import { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'

function Header() {

    const { IsSpecialSocialBrowser } = useContext(ThemeContext)

    const [isMobileVersion, setIsMobileVersion] = useState(window.innerWidth < 810);

    useEffect(() => {

        window.addEventListener( 'resize', onWindowResize );

        function onWindowResize() {

            if(window.innerWidth <= 810){
                setIsMobileVersion(true)
            }
        
            else{setIsMobileVersion(false)}
          }
          
        // Clean up the event listener when the component unmounts
        return () => {
        window.removeEventListener('resize', onWindowResize);}

    }, [isMobileVersion]);

    return (

        <div>

        {(isMobileVersion || IsSpecialSocialBrowser) &&
        <HeaderMobile/>
        }

        {(!isMobileVersion && !IsSpecialSocialBrowser) &&
        <HeaderComputer/>
        }



        </div>


    )
}

export default Header