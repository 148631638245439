import { createContext, useState } from 'react'

export const ThemeContext = createContext()

export function ThemeProvider({children}) {

    const [ContainerViewer, setContainerViewer] = useState([false,false,false,false,false,false,false]);

    const [changeBackgroungImage, setchangeBackgroungImage] = useState(false);

    function toggleValueOfSetContainerViewer(containerArrayObserver){

            setContainerViewer(containerArrayObserver)

    }

    function toggleValueOfsetchangeBackgroungImage(changeValue){

        setchangeBackgroungImage(changeValue)

}

    const isInstagramBrowser = document.referrer.includes('instagram.com');
    const isLinkedInReferrer = document.referrer.includes('linkedin.com');
    let isMobile = false
    let IsSpecialSocialBrowser = false;

    if (window.innerWidth < 810){
        isMobile = true;
    }

    if((isInstagramBrowser || isLinkedInReferrer) && isMobile){
        IsSpecialSocialBrowser = true;
    }
 
    return (
        <ThemeContext.Provider value={{ ContainerViewer, toggleValueOfSetContainerViewer, IsSpecialSocialBrowser, toggleValueOfsetchangeBackgroungImage, changeBackgroungImage}}>
            {children}
        </ThemeContext.Provider>
    )
}
