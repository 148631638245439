import styled, { keyframes } from 'styled-components'
import React from 'react'
import { useContext } from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'

// Animation keyframes for zooming
const zoomAnimation = keyframes`
  0% {
    transform: scale(1); /* Initial scale */
  }
  50% {
    transform: scale(1.1); /* Zoom in */
  }
  100% {
    transform: scale(1); /* Zoom out */
  }
`

// flex container global
const GlobalContainer = styled.div`
    color: white;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
    height: ${(props) => props.$IsSpecialSocialBrowser ? "700px" : "100vh"};
    width: 100vw;
    background: url('/data/home.webp') center center no-repeat ;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; 
`

// Separate div for the background image with zoom animation
const BackgroundImage = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: url('/data/home.jpg') center center no-repeat;
    background-size: cover;
    animation: ${zoomAnimation} 10s ease-in-out;
    z-index: 0; /* Ensure it's behind the content */
`

// overlay for the white opaque filter
const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(20, 20, 20, 0.6); /* White with 60% opacity */
    z-index: 1; /* Ensure it's above the background image */
`

const ContentWrapper = styled.div`
    position: relative;
    z-index: 2; /* Ensure the content is above the overlay */
    text-align: center;
`




const H1 = styled.h1`
    position: relative;
    font-size: 2.5rem;
    letter-spacing: 0.4rem;
    font-weight: 300;
    text-align: center;
    padding-top: 3.5rem;

    text-shadow: 0 0 10px #ffffff, /* Blanc éclatant */
                 0 0 20px #cccccc, /* Gris clair */
                 0 0 30px #999999, /* Gris moyen */
                 0 0 40px #666666, /* Gris foncé */
                 0 0 50px #333333; /* Très foncé */
    
    @media (max-width: 800px){
        font-size: 1rem;
        letter-spacing: 0.3rem;
    }

    @media (min-width: 1800px){
        font-size: 4rem;
    }
    @media (min-width: 2800px){
        font-size: 8rem;
    }

`

const H2 = styled.h2`
    font-size: 2.5rem;
    letter-spacing: 0.1rem;
    font-weight: bold;
    text-align: center;
    margin-top: 2rem;
    margin-bottom: 1rem;
    margin-right: 1rem;
    margin-left: 1rem;

    @media (max-width: 800px){
        font-size: 1.5rem;
        margin-left: 0.8rem;
        margin-right: 0.8rem;
        margin-bottom: 2rem;
    }

    @media (min-width: 1800px){
        font-size: 2.5rem;
    }
    @media (min-width: 2800px){
        font-size: 5rem;
    }
`

const H2bis = styled(H2)`
line-height: 2rem;
font-size: 2rem;
margin-top: 1rem;
margin-bottom: 0rem;
font-weight: 300;

@media (max-width: 800px){
        font-size: 1.5rem;

    }


`

const PresComponent = React.forwardRef((props,ref) => {

    const { IsSpecialSocialBrowser } = useContext(ThemeContext)

    return (

            <div>
                <GlobalContainer $IsSpecialSocialBrowser={IsSpecialSocialBrowser} >
                <BackgroundImage />
                <Overlay />
                    <ContentWrapper>
                    
                    <H1>Sébastien Petit</H1>
                    <H2>DÉVELOPPEUR WEB et ROBOTIQUE INDÉPENDANT & CORPORATE</H2>
                    <H2bis>&gt; Création de sites et applications Web</H2bis>
                    <H2bis>&gt; Expertise Robotique</H2bis>
                    </ContentWrapper>

                </GlobalContainer>

            </div>

)})


export default PresComponent
