import React, { useEffect} from 'react';
import * as THREE from 'three';

// Gltf loader and compressor
import { GLTFLoader } from 'three/addons/loaders/GLTFLoader.js';
import { DRACOLoader } from 'three/addons/loaders/DRACOLoader.js';

// Casual threejs package
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { MeshSurfaceSampler } from 'three/examples/jsm/math/MeshSurfaceSampler.js'
//import TWEEN from '@tweenjs/tween.js'

// Post processing library
import { EffectComposer } from 'three/addons/postprocessing/EffectComposer.js';
import { RenderPass } from 'three/addons/postprocessing/RenderPass.js';
import { UnrealBloomPass } from 'three/addons/postprocessing/UnrealBloomPass.js';
import { OutputPass } from 'three/addons/postprocessing/OutputPass.js';

function Model3dComponent({onModelLoad,height3d}) {

  useEffect(() => {

    ///// 1. INIT /////


    // Define params for unreal bloom effect
    const params = { threshold: 0, strength: 0.23, radius: 0.7, exposure: 0.5};

    // Draco loader to load draco compressed models from blender - gltf format
    const dracoLoader = new DRACOLoader()
    const loader = new GLTFLoader()
    dracoLoader.setDecoderPath('https://www.gstatic.com/draco/v1/decoders/')
    dracoLoader.setDecoderConfig({ type: 'js' })
    loader.setDRACOLoader(dracoLoader)

    // Create a scene
    const scene = new THREE.Scene();

    // Create a camera
    let camera = new THREE.PerspectiveCamera(60,window.innerWidth / window.innerHeight,0.1,1000);

    // Set the camera position
    camera.position.x = 0.8; 
    camera.position.y = 0.3; // Y on blender = Z on threejs
    camera.position.z = 0.65; // Z on blender = Y on threejs

    // Create a renderer and map to id container
    let renderer = new THREE.WebGLRenderer({ antialias: false, powerPreference: "high-performance" });
    renderer.setPixelRatio( window.devicePixelRatio );
    renderer.setSize(window.innerWidth, height3d);
    renderer.toneMapping = THREE.ReinhardToneMapping;
    const container = document.getElementById('three-container'); // Append the renderer to the container element
    container.appendChild(renderer.domElement);

    // Set the clear color (background color) to clear alpha channel with full opacity
    renderer.setClearColor(0x000000, 0);

    // Light gestation
    scene.add( new THREE.AmbientLight( 0xcccccc , 100) );

    // Add orbit control
    const controls = new OrbitControls( camera, renderer.domElement );

    function setOrbitControlsLimits(){
      controls.enableDamping = true
      controls.dampingFactor = 0.04
      controls.minDistance = 0.5
      controls.maxDistance = 9
      controls.enableZoom = false
    }


    ///// 2. POST PROCESSING BLOOM EFFECT /////


    // Post processing to apply bloom effect - provide the rendered scene as an input for the next post-processing step
    const renderScene = new RenderPass( scene, camera );

    // Post processing to apply bloom effect - UnrealBloomPass is going to use these image data to apply a bloom effect
    const bloomPass = new UnrealBloomPass( new THREE.Vector2( window.innerWidth, window.innerHeight ), 1.5, 0.4, 0.85 );
    bloomPass.threshold = params.threshold;
    bloomPass.strength = params.strength;
    bloomPass.radius = params.radius;

    // Post processing to apply bloom effect - the last pass in the chain which performs sRGB color space conversion and optional tone mapping
    const outputPass = new OutputPass();

    // Post processing to apply bloom effect - we create a composer by passing in an instance of WebGLRenderer. 
    let composer = new EffectComposer( renderer );
    composer.addPass( renderScene );
    composer.addPass( bloomPass );
    composer.addPass( outputPass )


///// 3. LOADING GLB/GLTF MODEL FROM BLENDER AND APPLY EFFECT /////


const clock = new THREE.Clock(); // keeping track of time
let mixer; // mixer is a player for animations on a particular object in the scene
var model;

  // adapt to screen size
  let path3dModel = '/data/3dModel/model3dPC.glb'

  if (window.innerWidth < window.innerHeight){
    path3dModel = '/data/3dModel/model3dMobile.glb'
  }

loader.load(path3dModel, function (gltf) {
    
    model = gltf.scene;

    // Access the animations within the loaded model
    const animations = gltf.animations;

    // Create a mixer for the animations
    mixer = new THREE.AnimationMixer(model);

    // Attach each animation clip to the mixer
    animations.forEach((clip) => {
      mixer.clipAction(clip).play();
    });

    // traverse the entire model and apply modification
    model.traverse(function (childMesh) {

      // Apply opacity effect
      if (childMesh.name === "Jellyfish_01" || childMesh.name === "Jellyfish_02" || childMesh.name === "Jellyfish_03"  || childMesh.name === "Jellyfish_04" || childMesh.name === "Jellyfish_05"){
        const material = childMesh.children[0].material;
        const material1 = childMesh.children[1].material;
        const material2 = childMesh.children[2].material;
        material.transparent = true; 
        material.opacity = 0.01;
        material1.transparent = true;
        material1.opacity = 0.01;
        material2.transparent = true;
        material2.opacity = 0.01;
      }

      // Apply point cloud effect
      else if (childMesh.name === "Icosphere") {
        let sampler = new MeshSurfaceSampler(childMesh).build()
        transformMesh(sampler, true)
      }

      // Apply point cloud effect
      else if (childMesh.name === "Submarine") {
        let sampler = new MeshSurfaceSampler(childMesh.children[0]).build()
        transformMesh(sampler, false)
      }

      else if (childMesh.name === "NormalFish_01" || childMesh.name === "CorailFish_01" || childMesh.name === "WeirdFish"  || childMesh.name === "Shark" || childMesh.name === "PufferFish" || childMesh.name === "19415_Whale_Shark_v1") {
        const material = childMesh.material;
        material.transparent = true;
        material.opacity = 0.2;
      }

    })

  // delete mesh which are transform to points
  const meshToDelete1 = model.getObjectByName('Icosphere'); // Replace 'MeshName' with the name of the mesh to be deleted.
  if (meshToDelete1) {
  model.remove(meshToDelete1);
  }

  const meshToDelete2 = model.getObjectByName('Submarine'); // Replace 'MeshName' with the name of the mesh to be deleted.
  if (meshToDelete2) {
  model.remove(meshToDelete2);
  }

    // we add gltf to global scene
    scene.add(model);

    onModelLoad();

    //introAnimation() // call intro animation on start

    // launch animate part
    animate();
    
  })


///// 4. TRANSFORM MESH INTO POINTS /////




function transformMesh(sampler, modelBool){

  let pointsGeometry = new THREE.BufferGeometry()
  const vertices = []
  const tempPosition = new THREE.Vector3()

    // Loop to sample a coordinate for each points
    for (let i = 0; i < 100000; i ++) {
      // Sample a random position in the model
      sampler.sample(tempPosition)
      // Push the coordinates of the sampled coordinates into the array
      vertices.push(tempPosition.x, -tempPosition.z, tempPosition.y)
    }
    
    // Define all points positions from the previously created array
    pointsGeometry.setAttribute('position', new THREE.Float32BufferAttribute(vertices, 3))

  // adapt to screen size
  let opacity = 0.4
  let sizepx = 0.001

  if (window.innerWidth < window.innerHeight){
    sizepx = 0.002
    if(modelBool){
      sizepx = 0.005
      opacity = 0.05
    }
  }

    const pointsMaterial = new THREE.PointsMaterial({
        color: 0xffffff,
        size: sizepx,
        blending: THREE.AdditiveBlending,
        transparent: true,
        opacity: opacity,
        depthWrite: false,
        sizeAttenuation: true,
    })

    // Create an instance of points based on the geometry & material
    const points = new THREE.Points(pointsGeometry, pointsMaterial)

    // Add them into the main group
    scene.add(points)

}


///// 5. ADD EVENT MOUSE AND SCROLL ON BLOOM EFFECT AND RESIZE /////


window.addEventListener( 'resize', onWindowResize );
/* window.addEventListener( 'mousemove', onWindowMouseMove ); */
/* window.addEventListener( 'scroll', onWindowScroll ); */

if (window.innerWidth < window.innerHeight){
  bloomPass.radius = 1
}


function onWindowResize() {
  if(window.innerWidth > 810){
  const width = window.innerWidth;
  const height = window.innerHeight;
  camera.aspect = width / height;
  camera.updateProjectionMatrix();
  renderer.setSize( width, height );
  composer.setSize( width, height );
  }
}

/* let ratioMouseWidth */

/* function onWindowMouseMove(event) {
  let mousePosX = event.clientX
  ratioMouseWidth = mousePosX/window.innerWidth
  bloomPass.radius = Number( ratioMouseWidth );
  console.log(ratioMouseWidth)
}; */

function onWindowScroll() {
  let scrollTop = window.scrollY;
  let winHeight = window.innerHeight;
  let scrollPercent = scrollTop / winHeight;
  bloomPass.strength = Number( scrollPercent/3);
};


///// 6. INTRO CAMERA ANIMATION USING TWEEN /////


/* function introAnimation() {
  controls.enabled = false //disable orbit controls to animate the camera

  // adapt to screen size
  let PositionCameraX = 0.8
  let PositionCameraZ = 0.65
  let durationIntro = 6500

  if (window.innerWidth < window.innerHeight){
    PositionCameraX = -3
    PositionCameraZ = 2
    durationIntro = 6000
  }
  
  new TWEEN.Tween(camera.position.set(1,-0.3,4 )).to({ x: PositionCameraX,y: 0.3,z: PositionCameraZ}, durationIntro)
  .easing(TWEEN.Easing.Quadratic.InOut).start()
  .onComplete(function () { //on finish animation
      controls.enabled = true //enable orbit controls
      setOrbitControlsLimits() //enable controls limits
      TWEEN.remove(this) // remove the animation from memory
  })
} */




///// 7. ANIMATION LOOP /////

    function animate() {

      requestAnimationFrame(animate);

      //TWEEN.update() // update intro camera

      scene.rotation.y += 0.0045;

      controls.update() // update orbit controls

      mixer.update(clock.getDelta()); // Update the animation mixer

      composer.render(); // render with composer (bloom effect)
      //renderer.render( scene, camera );

      controls.enabled = true
      setOrbitControlsLimits()

    }

    return () => {
      window.removeEventListener('resize', onWindowResize);
      /* window.removeEventListener('mousemove', onWindowMouseMove); */
      window.removeEventListener('scroll', onWindowScroll);
    };
    
  }, []);

  return <div style={{zIndex:"10", position:"absolute", top:"0", left:"0"}}/>;
}

export default Model3dComponent;