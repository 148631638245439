import { NavLink } from 'react-router-dom'
import styled from 'styled-components'
import AtomHamburgerStyle from "./AtomHamburgerStyle"
import {useState, useEffect, useContext, useRef} from 'react'
import { ThemeContext } from '../../utils/context/ThemeProvider'
import { ClickOutside } from '../../utils/hooks/ClickOutside';

// header container
const HeaderContainer = styled.div`
    display:flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    position: fixed;
    z-index: 12;
    top:0;
    width: 100%;
    backdrop-filter: blur(${(props) => (props.$scrolledScaleBlurPx + 'px')});
    background-color:  rgb(32, 32, 32);
    transform: ${(props) => props.$active ?  "translateY(-1%)" : "translateY(-105%)"};
    transition: all 0.4s ease-out;
    border-bottom: 1px solid; 
    border-color: rgb(170, 170, 170);
`

// company name
const H1 = styled.h1`
    color: white;
    margin:  0.5rem 0rem 0.5rem 0rem;
    font-size: 1rem;
    letter-spacing: 0.1rem;
    text-align: center;

    @media (min-width: 450px){
        font-size: 1rem;
    }
`

// global div of link
const StyledNavLinkDIV = styled.nav`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 3rem;

`

// Link of navbar
const StyledHashLink = styled(NavLink)`
    position:relative;
    text-decoration: none;
    transition:0.2s;
    width: 100vw;
    text-align: center;
    color: white;
    font-weight: bold;
    font-size: 1rem;
    margin: 0.4rem 0rem;
    padding: 0.4rem 0.7rem;
    border-radius: 10px;
    background-color: rgb(38, 38, 38);


    @media (min-width: 450px){
        font-size: 1.5rem;
    }
    


    color: ${(props) => props.$activate ? "rgb(170, 170, 170)" : ""};
`

const IMG = styled.img.attrs(props => ({
    src:  props.$activate ?  "/data/images/logo/header/home.webp" : "/data/images/logo/header/homew.webp",
  }))`
    width: 50px;
    height: 20px;
  `;

const ALinkedin = styled.a`

color: white;
transition : color 0.2s;

&:hover{
    color:#087BB9;
}
`

function HeaderMobile() {

    const [active, setActive] = useState(false);
    const [activeWait, setActiveWait] = useState(false);

    const { ContainerViewer } = useContext(ThemeContext)

    const wrapperRef = useRef();
    ClickOutside(wrapperRef, () => setActive(false));

    useEffect(() => {
        if (activeWait !== null){
            if(ContainerViewer[activeWait] === true){
                setActiveWait(null);
                setActive(false);
            }
        }
    },[ContainerViewer, activeWait])
    
    return (

        <div ref={wrapperRef} >

            <AtomHamburgerStyle active={active} setActive={setActive}/>

            <HeaderContainer $active={active}>
                
                    <StyledNavLinkDIV $active={active}>
                        <H1 $active={active} style={{marginTop:"4rem", marginBottom:"1rem"}}>Sébastien Petit</H1>
                        <img src="/data/images/logo/header/logo.webp" alt="logo du site" style={{width:"45px", height:"45px"}}></img>
                        <H1 $active={active} style={{marginTop:"1rem", marginBottom:"1rem"}}>Ingénieur Robotique <br></br> & <br></br> Développeur Web</H1>

                        <div style={{display:"flex", flexDirection:"row", marginBottom:"2.5rem"}}>
                        <ALinkedin href="https://www.linkedin.com/in/s%C3%A9bastien-petit-rob/" target="_blank" rel="noreferrer">  <i className="fa-brands fa-linkedin-in fa-lg"></i> </ALinkedin>
                        {/* <AThreads style={{marginRight:"1rem"}} href="https://www.threads.net/@sebastiengoodspeed" target="_blank" rel="noreferrer">  <i className="fa-brands fa-threads fa-lg"></i> </AThreads> */}
                        {/* <AInsta href="https://www.instagram.com/sebastiengoodspeed/" target="_blank" rel="noreferrer">  <i className="fa-brands fa-instagram fa-lg"></i> </AInsta> */}
                        </div>
                        <StyledHashLink onClick={() => (ContainerViewer[0] === true) ? setActive(false) : setActiveWait(0)} $activate={ContainerViewer[0]} to="/#home">Accueil</StyledHashLink>
                        <StyledHashLink onClick={() => (ContainerViewer[1] === true) ? setActive(false) : setActiveWait(1)} $activate={ContainerViewer[1]} to="/#submarine3d"><IMG $activate={ContainerViewer[1]}></IMG></StyledHashLink>
                        <StyledHashLink onClick={() => (ContainerViewer[2] === true) ? setActive(false) : setActiveWait(2)} $activate={ContainerViewer[2]} to="/#servicesWeb">Services Web</StyledHashLink>
                        <StyledHashLink onClick={() => (ContainerViewer[3] === true) ? setActive(false) : setActiveWait(3)} $activate={ContainerViewer[3]} to="/#servicesRob">Services Robotique</StyledHashLink>
                        <StyledHashLink onClick={() => (ContainerViewer[4] === true) ? setActive(false) : setActiveWait(4)} $activate={ContainerViewer[4]} to="/#work">Réalisations</StyledHashLink>
                        <StyledHashLink onClick={() => (ContainerViewer[5] === true) ? setActive(false) : setActiveWait(5)} $activate={ContainerViewer[5]} to="/#contact">Contact & Tarifs</StyledHashLink>
                        <StyledHashLink onClick={() => (ContainerViewer[6] === true) ? setActive(false) : setActiveWait(6)} $activate={ContainerViewer[6]} to="/#about">À Propos</StyledHashLink>
                        
                        
                    </StyledNavLinkDIV>
        
            </HeaderContainer>

        </div>

    )
}

export default HeaderMobile